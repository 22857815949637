import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // 左侧菜单栏数据
        menuItems: [
            // 首页
            {
                name: 'home', // 要跳转的路由名称 不是路径
                type: 'el-icon-nav_icon_sy', // icon类型
                text: '首页', // 文本内容
            },
            // 钱包管理
            {
                text: '钱包管理',
                name: 'wallet',
                type: 'el-icon-wallet',
                children: [
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'externalWallet',
                        show: 'WALLET_TERRACE_SHOW',
                        text: '平台钱包',
                    },
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'internalWallet',
                        show: 'WALLET_XIANGM_SHOW',
                        text: '乡米网钱包',
                    }
                ]
            },
            // 商户管理
            {
                text: '商户管理',
                name: 'merchant',
                type: 'el-icon-document',
                children: [
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'merchantList',
                        show: 'MERCHANT_LIST_SHOW',
                        text: '商户列表',
                    },
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'merchantOrder',
                        show: 'MERCHANT_ORDER_SHOW',
                        text: '商户订单',
                    },
                ]
            },
            // 订单管理
            {
                text: '订单管理',
                name: 'order',
                type: 'el-icon-document',
                children: [
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'externalOrder',
                        show: 'ORDER_TERRACE_SHOW',
                        text: '平台订单',
                    },
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'internalOrder',
                        show: 'ORDER_XIANGM_SHOW',
                        text: '乡米网订单',
                    },
                ]
            },
            // 权限管理
            {
                text: '权限管理',
                name: 'jurisdiction',
                type: 'el-icon-setting',
                children: [
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'jurisdictionRole',
                        text: '角色管理',
                    },
                    {
                        type: 'el-icon-nav_icon_jy',
                        name: 'jurisdictionLog',
                        text: '管理人日志',
                    },
                ]
            }

        ],
        // 页面路径总和名称
        pathTotal: [
            {
                key: 'HOME_SHOW', // 首页页面展示
                value: 'home'
            },
            {
                key: 'HOME_FUNC', // 首页功能
                value: 'home'
            },
            {
                key: 'WALLET_TERRACE_SHOW',
                value: 'externalWallet'
            },
            {
                key: 'WALLET_TERRACE_FUNC',
                value: 'externalWallet'
            },
            {
                key: 'WALLET_XIANGM_SHOW',
                value: 'internalWallet'
            },
            {
                key: 'WALLET_XIANGM_FUNC',
                value: 'internalWallet'
            },
            {
                key: 'ORDER_TERRACE_SHOW',
                value: 'externalOrder'
            },
            {
                key: 'ORDER_TERRACE_FUNC',
                value: 'externalOrder'
            },
            {
                key: 'ORDER_XIANGM_SHOW',
                value: 'internalOrder'
            },
            {
                key: 'ORDER_XIANGM_FUNC',
                value: 'internalOrder'
            },
            //----------商户
            {
                key: 'MERCHANT_LIST_SHOW',
                value: 'merchantList'
            },
            {
                key: 'MERCHANT_LIST_FUNC',
                value: 'merchantList'
            },
            {
                key: 'MERCHANT_ORDER_SHOW',
                value: 'merchantOrder'
            },
            {
                key: 'MMERCHANT_ORDER_FUNC',
                value: 'merchantOrder'
            },

        ],
        // 页面功能是否开启使用
        pageFunctionShow: false
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems.push(items)
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
        //  判断当前页面功能是否完善使用
        setPerfect(state, perfeactshow) {
            // console.log('实时修改更换', perfeactshow)
            state.pageFunctionShow = perfeactshow
        },


        //--------------开始总计算
        totalAuchao(state, item) {
            console.log('接口返回', item)

            state.menuItems.forEach((item, index) => {
                if (item.name == 'jurisdiction') {
                    state.menuItems.splice(index, 1)
                }

            });


        },
        // 第一区域
        totalAuthority(state, items) {
            let indexLockdownArray = items

            // 先判断当前数组中是否有首页
            let hashHome = indexLockdownArray.includes("HOME_SHOW");

            let hashWallet0 = indexLockdownArray.includes(
                "WALLET_TERRACE_SHOW"
            ); // 平台钱包页面展示
            let hashWallet1 = indexLockdownArray.includes("WALLET_XIANGM_SHOW"); // 乡米网钱包页面展示
            let hashOrder0 = indexLockdownArray.includes("ORDER_TERRACE_SHOW"); // 平台订单页面展示
            let hashOrder1 = indexLockdownArray.includes("ORDER_XIANGM_SHOW"); // 乡米网订单页面展示
            console.log("是否存在这个值", hashOrder1);
            let merchantOrder0 = indexLockdownArray.includes("MERCHANT_LIST_SHOW"); // 乡米网=商户列表页面展示
            let merchantOrder1 = indexLockdownArray.includes("MERCHANT_ORDER_SHOW"); // 乡米网商户订单页面展示

            state.menuItems.forEach((item, index) => {
                if (hashHome == false) {
                    if (item.name == "home") {
                        state.menuItems.splice(index, 1);
                    }
                }

                if (hashWallet0 == false || hashWallet1 == false) {
                    if (item.name == "wallet") {
                        for (let i = 0; i < item.children.length; i++) {
                            if (hashWallet0 == false) {
                                if (item.children[i].show == "WALLET_TERRACE_SHOW") {
                                    item.children.splice(0, 1);
                                }
                            }

                            if (hashWallet1 == false) {
                                if (item.children[i].show == "WALLET_XIANGM_SHOW") {
                                    item.children.splice(1, 1);
                                }
                            }
                        }
                    }
                }
                if (hashWallet0 == false && hashWallet1 == false) {
                    if (item.name == "wallet") {
                        console.log("请求1");

                        state.menuItems.splice(index, 1);
                    }
                }
            });

            state.menuItems.forEach((item, index) => {
                // 订单
                if (hashOrder0 == false || hashOrder1 == false) {
                    // 订单下的一个存在 一个不存在
                    if (item.name == "order") {
                        for (let i = 0; i < item.children.length; i++) {
                            if (hashOrder0 == false) {
                                if (item.children[i].show == "ORDER_TERRACE_SHOW") {
                                    item.children.splice(0, 1);
                                }
                            }

                            if (hashOrder1 == false) {
                                if (item.children[i].show == "ORDER_XIANGM_SHOW") {
                                    item.children.splice(1, 1);
                                }
                            }
                        }
                    }
                }

                if (hashOrder0 == false && hashOrder1 == false) {
                    if (item.name == "order") {
                        state.menuItems.splice(index, 1);
                    }
                    // 平台订单页面展示 不存在
                }
            });

            state.menuItems.forEach((item, index) => {
                // 订单
                if (merchantOrder0 == false || merchantOrder1 == false) {
                    // 订单下的一个存在 一个不存在
                    if (item.name == "merchant") {
                        for (let i = 0; i < item.children.length; i++) {
                            if (merchantOrder0 == false) {
                                if (item.children[i].show == "MERCHANT_LIST_SHOW") {
                                    item.children.splice(0, 1);
                                }
                            }

                            if (merchantOrder1 == false) {
                                if (item.children[i].show == "MERCHANT_ORDER_SHOW") {
                                    item.children.splice(1, 1);
                                }
                            }
                        }
                    }
                }

                if (merchantOrder0 == false && merchantOrder1 == false) {
                    if (item.name == "merchant") {
                        state.menuItems.splice(index, 1);
                    }
                    // 平台订单页面展示 不存在
                }
            });

        },


    },
})

export default store
