import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//-----自适应
import './utils/rem'

//-------网络请求
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$http = axios

//-------页面权限
import './permission'
//-------框架组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI)

//-----------  引入ico
import './common/font/iconfont.css'
import './common/fontIcon/iconfont.css'
import './common/font-login/iconfont.css'
import './common/font-tab/iconfont.css'

// -------统计表
import * as echarts from 'echarts' //引入echarts
Vue.prototype.$echarts = echarts //引入组件


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
